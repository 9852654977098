.container {
    max-width: 350px;
    min-width: 200px;
    width: 100%;
    height: fit-content;
    background-color: rgb(255, 255, 255);

    display: flex;
    flex-direction: column;
    padding: 20px;
    
    border-radius: 10px;
    gap: 5px;
    box-shadow: 0px 0px 3px rgb(196, 196, 196);;
}

.Top {
    width: 100%;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Top h3 {
    font-size: 18px;
    font-weight: 400;
}

.spinner img {
    width: 44.5px;
    height: 44.5px;
    /* Makes the border a circle */
    animation: spin 1s linear infinite;
    /* Apply the animation */
}

/* Define the @keyframes animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    /* Start with no rotation */
    100% {
        transform: rotate(360deg);
    }

    /* Rotate a full circle */
}


.Top-left h1 {
    font-size: 40px;
    margin: 0;
    font-weight: 700;
    color: rgb(88, 87, 87);

}
.users h1 {
    margin: 0;
    font-weight: 500;
    color: rgb(22, 138, 173);
}

.devicesOnline h1 {
    margin: 0;
    font-weight: 500;
    color: rgb(25, 162, 103);
}

.devicesOffline h1 {
    margin: 0;
    font-weight: 500;
    color: rgb(255, 79, 112)
}

.totalCount h1 {
    margin: 0;
    font-weight: 500;
    color: rgb(22, 138, 173);
}

.emptyCount h1 {
    margin: 0;
    font-weight: 500;
    color: #fdc16a;
}

.sitesOfflineMoreThanMonth h1 {
    margin: 0;
    
    font-weight: 500;
    color: rgb(22, 138, 173);
}

.sitesOfflineUnused h1 {
    font-size: 12px;
    margin: 0;
    font-weight: 500;
    color: rgb(22, 138, 173);
}

.activeSites {
    margin: 0;
    padding: 0px;

}
.activeSites p {
    margin: 0;
    font-size: 12px;
    color: #676666;
}

.name h1 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    color: rgb(22, 138, 173);
}

.name h3 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    color: rgb(22, 138, 173);
}

.totalAccess h1 {
    margin: 0;
    font-weight: 500;
    font-size: 25px;
    color: rgb(22, 138, 173);
}



.emailAddress h1 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: rgb(22, 138, 173);
}

.emailAddress h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: rgb(22, 138, 173);
}

.modifiable {
    cursor: pointer;
}

.modifiable h1 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    color: #ffad31
}

.modifiable h3 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    color: rgb(22, 138, 173);
}

.Top-right {
    width: 110px;
    background-color: rgb(255, 255, 255);

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-left: 7px;
}

.string1 h2 {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 3px 6px;
    font-size: 11px;
    border-radius: 12px;
    color: white;
    font-weight: bold;
    margin: 0;
    background-color: #22ca80;

}

.string2 h2 {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 3px 6px;
    font-size: 11px;
    border-radius: 12px;
    color: white;
    font-weight: bold;
    margin: 0;
    background-color: #fdc16a;
}

.string3 h2 {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 3px 6px;
    font-size: 11px;
    border-radius: 12px;
    color: white;
    font-weight: bold;
    margin: 0;
    background-color: #ff4f70;
}


.Top-left {
    width: 100%;
    height: 20%;
    background-color: rgb(255, 255, 255);

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.Top-left h3 {

    color: #9eabc0;
    margin: 0;

    font-size: 14px;
    font-weight: 400;
}

.Bottom {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.icon {
    width: 35px;
    height: 35px;
    filter: grayscale(100%);
}

@media screen and (max-width: 1500px) {

    .container {
        padding: 15px;
        height: fit-content;
        width: 300px;
    }

    .body {
        font-size: 10px;
    }


    .Top h3 {
        font-size: 15px;
    }


    .Top-left h1 {
        font-size: 25px;
    }

    .icon {
        width: 25px;
        height: 25px;
        filter: grayscale(100%);
    }

    .spinner img {
        width: 24.5px;
        height: 24.5px;

        /* Makes the border a circle */
        animation: spin 1s linear infinite;

        /* Apply the animation */
    }

    .name h1 {
        margin: 0;
        font-weight: 600;
        font-size: 13px;
        color: rgb(22, 138, 173);
    }
    
    .name h3 {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        color: rgb(22, 138, 173);
    }

    .totalAccess h1 {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        color: rgb(22, 138, 173);
    }
    
    
    .emailAddress h1 {
        margin: 0;
        font-weight: 600;
        font-size: 13px;
        color: rgb(22, 138, 173);
    }
    
    .emailAddress h3 {
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        color: rgb(22, 138, 173);
    }

    .modifiable h1 {
        margin: 0;
        font-weight: 600;
        font-size: 13px;
        color: #ffad31
    }
    
    .modifiable h3 {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        color: rgb(22, 138, 173);
    }
    

}