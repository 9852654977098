/*--------------------------------DEFAULT--------------------------------*/

.inputField {
    width: 100%;
    padding: 12px;
    margin-bottom: 16px;
    font-size: 16px;
    border: 2px solid #ccc; /* Default border */
    border-radius: 10px; /* Rounded corners */
    background-color: transparent;
    color: white;
    outline: none;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.inputField:focus {
    border-color: #2563eb; /* Change to match app color */
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.5);
}


.errorMessage {
    color: rgb(245, 79, 79);
    margin-bottom: 10px;
    padding: 5px;
}


/*--------------------------------KNOWIO--------------------------------*/
.KNOWIO.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #1a1a23;
}

.KNOWIO .inputField:focus {
    border-color: #2563eb;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
}

.KNOWIO .inputField {
    border-color: #40aade;
}

.KNOWIO.card {
    background: #292a38;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.KNOWIO .title {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
}

.KNOWIO .subTitle {
    color: white;
}

.KNOWIO .pinContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    color: white;
    background: #292a38;
}

.KNOWIO .pinInput {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 2px solid #40aade;
    background: #292a38;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
}

.KNOWIO .pinInput:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
}

.KNOWIO .button {
    width: 100%;
    background-color: #3b82f6;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
}

.KNOWIO .button:hover {
    background-color: #2563eb;
}

.KNOWIO .button:disabled {
    background-color: #9ca3af;
    cursor: not-allowed;
}




/*--------------------------------RESURE--------------------------------*/

.RESURE.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #290a53;
}

.RESURE.card {
    background: #3d0f6b;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.RESURE .title {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
}

.RESURE .subTitle {
    color: white;
}

.RESURE .pinContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    color: white;
    background: #3d0f6b;
}

.RESURE .pinInput {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 2px solid #5ee784;
    background: #3d0f6b;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
}

.RESURE .pinInput:focus {
    border-color: #5ee784;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
}

.RESURE .button {
    width: 100%;
    background-color: #5ee784;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
}

.RESURE .button:hover {
    background-color: #5ee784;
}

.RESURE .button:disabled {
    background-color: #9ca3af;
}

.RESURE .inputField {
    border-color: #5ee784;
}

.RESURE .inputField:focus {
    border-color: #2563eb;
    box-shadow: 0 0 8px rgba(231, 76, 60, 0.5);
}
    

/*--------------------------------Portal--------------------------------*/

.PORTAL_SECURITY.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #193356;
}

.PORTAL_SECURITY .card {
    background: #1d3c66;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.PORTAL_SECURITY .title {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
}

.PORTAL_SECURITY .subTitle {
    color: white;
}

.PORTAL_SECURITY .pinContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    color: white;
    background: #1d3c66;
}

.PORTAL_SECURITY .pinInput {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 2px solid #db2040;
    background: #1d3c66;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
}

.PORTAL_SECURITY .pinInput:focus {
    border-color: #db2040;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
}

.PORTAL_SECURITY .button {
    width: 100%;
    background-color: #db2040;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
}

.PORTAL_SECURITY .button:hover {
    background-color: #db2040;
}

.PORTAL_SECURITY .button:disabled {
    background-color: #9ca3af;
}

.PORTAL_SECURITY .inputField {
    border-color: #db2040;
}

.PORTAL_SECURITY .inputField:focus {
    border-color: #2563eb;
    box-shadow: 0 0 8px rgba(231, 76, 60, 0.5);
}
    

/*------------------------------Parfas-----------------------------*/
.PARFAS.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #000000;
}

.PARFAS .card {
    background: #2e2e2e;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.PARFAS .title {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
}

.PARFAS .subTitle {
    color: white;
}

.PARFAS .pinContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    color: white;
    background: #2e2e2e;
}

.PARFAS .pinInput {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 2px solid #ffffff;
    background: #2e2e2e;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
}

.PARFAS .pinInput:focus {
    border-color: #ffffff;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
}

.PARFAS .button {
    width: 100%;
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
}

.PARFAS .button:hover {
    background-color: #ffffff;
}

.PARFAS .button:disabled {
    background-color: #9ca3af;
}

.PARFAS .inputField {
    border-color: #ffffff;
}

.PARFAS .inputField:focus {
    border-color: #2563eb;
    box-shadow: 0 0 8px rgba(231, 76, 60, 0.5);
}

/*------------------------------Magnum-----------------------------*/
.Magnum.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #08085e;
}

.Magnum .card {
    background: #172469;
    padding: 24px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.Magnum .title {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
}

.Magnum .subTitle {
    color: white;
}

.Magnum .pinContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    color: white;
    background: #172469;
}

.Magnum .pinInput {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 2px solid #937455;
    background: #172469;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s;
}

.Magnum .pinInput:focus {
    border-color: #937455;
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5);
}

.Magnum .button {
    width: 100%;
    background-color: #937455;
    color: rgb(0, 0, 0);
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
}

.Magnum .button:hover {
    background-color: #937455;
}

.Magnum .button:disabled {
    background-color: #9ca3af;
}

.Magnum .inputField {
    border-color: #937455;
}

.Magnum .inputField:focus {
    border-color: #2563eb;
    box-shadow: 0 0 8px rgba(231, 76, 60, 0.5);
}
    