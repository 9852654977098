.CamectContentLayout {
    width: 100%;
    /* min-height: 141px; */
    height: 100%;
    display: flex;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    /* gap: 10px; */
    cursor: default;
    align-items: center;
    justify-content: space-between;
}

.left-wrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 5px;
}


.subtitle {
    width: 200px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 200; 
}

.right-wrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    position: relative;
    gap: 5px;
}

.text-container{
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    
}

.text-container:hover h4 {
    color: rgb(164, 164, 164);
}


.text-container:hover h4 {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.pinName {
    color: var(--Primary-Color);
}

.Icons-Container {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;

    align-items: center;
    /* position: absolute; */
    gap: 5px;
}

.Icon {
    width: 40px;
    height: 40px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.Icon:hover{
    cursor: pointer;
    transform: translatey(-2px);
}

.RemoveLockIcon{
    width: 40px;
    height: 40px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.removeCamectIcon{
    width: 20px;
    height: 20px;
    /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
    background-repeat: no-repeat;
    /* Set the transition duration */
    transition: transform 0.3s;
    cursor: pointer;
}

.RemoveLockIcon:hover{
    cursor: pointer;
    transform: translatey(-2px);
    filter: invert(100%);
}

.EditList{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-self: center; 
    justify-content: center;
}

.PinsList{
   display: flex;
   flex-direction: column;
   gap: 10px;
   align-self: flex-start; 
   justify-content: center;
   align-items: center;
}

.title {
    max-height: 100px;
    font-weight: 600;
    margin: 0px;
    font-size: 20px;
    width: fit-content;
}


.subtitle {
    max-height: 100px;
    max-width: 200px;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
    width: 100%; /* Ensures it respects the parent's width */
    overflow: hidden;
    padding-right: 10px; 
}

.subPinText{
    font-weight: 400;
    margin: 0px;
}

.CancelButton:hover{
    background-color: rgb(227, 17, 17);
}

/* Assuming you use CSS modules or similar */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 400px;
    max-width: 100%;
}

.closeButton {
    background: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.saveButton {
    background: green; /* Change background color for the save button */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    float: right; /* Position the button to the right */
    /* Optional: Add some margin to separate it from other elements */
    margin-left: 10px;
}

.modalTitle {
    margin-top: 10;
}

.PinsList_new {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 500px; /* Set a maximum height */
    overflow-y: scroll; /* Always show the vertical scrollbar */
}

/* Optional: Style the scrollbar for better appearance (for Webkit browsers) */
.PinsList_new::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.PinsList_new::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the track */
}

.PinsList_new::-webkit-scrollbar-thumb {
    background: #888; /* Colour of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners */
}

.PinsList_new::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker colour when hovered */
}


.container {
    
    gap: 16px;
}

.spacing {
    margin-bottom: 16px;
}

/* .cameraName {
    position: relative;
}

.pinNames {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    padding: 10 10px;
}

.upperRight {
    align-self: flex-end;
    text-align: right;
}

.lowerRight {
    align-items: flex-end;
    text-align: right;
} */

.camera-name {
    position: relative; /* Make this element a containing block for absolute positioning */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/* Pin names container */
.pinNames_new {
    position: relative;
    flex-grow: 1;
    text-align: right;
}

/* Upper right positioning */
.upperRight {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px; /* Adjust font size if necessary */
    color: #666; /* Adjust color if necessary */
}

/* Lower right positioning */
.lowerRight {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 12px;
    color: #666;
}

/* ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc; 
} */


.cameraItem {
    padding: 30px;
    margin-bottom: 10px;
    border: 1px solid transparent; /* Default border color */
    border-radius: 5px;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.cameraItem:hover {
    border-color: var(--Primary-Color); /* Change border color on hover */
}

.selected {
    border-color: var(--Primary-Color); /* Highlight selected item */
    background-color: #ffffff; /* Optional: light background for selected item */
}

.loadingOverlay {
    width: 100%;
    /* min-height: 141px; */
    height: 100%;
    display: flex;
    padding: 25px 25px;
    padding-top: 10px;
    border-radius: 12px;
    text-decoration: none;
    /* gap: 10px; */
    cursor: default;
    align-items: center;
    justify-content: space-between;




    position: absolute;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */


    z-index: 1000; /* Ensure the overlay is above modal content */
}

.loadingSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.errorMessage {
    color: red;
    background-color: #fdd;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid red;
    border-radius: 5px;
}

@media screen and (max-width: 1500px) {

    .subtitle {
        max-width: 165px;
        max-height: 100px; /* Ensures the text height doesn't exceed this */
        font-weight: 400;
        margin: 0px;
        font-size: 15px;
        width: 100%; /* Ensures it respects the parent's width */
        overflow: hidden;
        padding-right: 10px; /* Hides overflowed content */
    }

    .title {
        max-height: 100px;
        font-weight: 600;
        margin: 0px;
        font-size: 16px;
        width: fit-content;
    }
    
    .text-container{
        display: flex;
        gap: 10px;
        cursor: pointer;
        align-items: center;
        
    }
    
    .text-container:hover h4 {
        color: rgb(164, 164, 164);
    }
    
    .text-container:hover h4 {
        text-decoration: underline;
        text-underline-offset: 3px;
    }

    .pinName {
        font-size: 14px;
        color: var(--Primary-Color);
    }
    
    .Icons-Container {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        /* align-items: flex-end; */
        /* position: absolute; */
        gap: 5px;
    }
    
    .Icon {
        width: 35px;
        height: 35px;
        /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
        background-repeat: no-repeat;
        /* Set the transition duration */
        transition: transform 0.3s;
        cursor: pointer;
    }
    
    .Icon:hover{
        cursor: pointer;
        transform: translatey(-2px);
    }
    
    .RemoveLockIcon{
        width: 40px;
        height: 40px;
        /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
        background-repeat: no-repeat;
        /* Set the transition duration */
        transition: transform 0.3s;
        cursor: pointer;
    }
    
    .removeCamectIcon{
        width: 20px;
        height: 20px;
        /* background-image: url('../../../../../public/assets/PortalIconnographySVGs/PortalAppRealted_Icons/Table%20Icons/Pagination%20Icon/__Page%20-%20Forward.svg'); */
        background-repeat: no-repeat;
        /* Set the transition duration */
        transition: transform 0.3s;
        cursor: pointer;
    }
    
    .RemoveLockIcon:hover{
        cursor: pointer;
        transform: translatey(-2px);
        filter: invert(100%);
    }
    
    .EditList{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-self: center; 
        justify-content: center;
    }
    
    .PinsList{
       display: flex;
       flex-direction: column;
       gap: 10px;
       align-self: flex-start; 
       justify-content: center;
       align-items: center;
    }
    
    .subPinText{
        font-weight: 400;
        margin: 0px;
        font-size: 16px;
    }
    
    .CancelButton:hover{
        background-color: rgb(227, 17, 17);
    }
    
    /* Assuming you use CSS modules or similar */
    
    .modalOverlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; 
    }
    
    .modalContent {
        background: white;
        padding: 20px;
        border-radius: 5px;
        width: 400px;
        max-width: 100%;
    }
    
    .closeButton {
        background: red;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;
    }
    
    .saveButton {
        background: green; /* Change background color for the save button */
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px;
        cursor: pointer;
        float: right; /* Position the button to the right */
        /* Optional: Add some margin to separate it from other elements */
        margin-left: 10px;
    }
    
    .modalTitle {
        margin-top: 10;
    }
    
    .PinsList_new {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    /* .cameraName {
        position: relative;
    }
    
    .pinNames {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        justify-content: space-between;
        align-items: center;
        padding: 10 10px;
    }
    
    .upperRight {
        align-self: flex-end;
        text-align: right;
    }
    
    .lowerRight {
        align-items: flex-end;
        text-align: right;
    } */
    
    .camera-name {
        position: relative; /* Make this element a containing block for absolute positioning */
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    
    /* Pin names container */
    .pinNames_new {
        position: relative;
        flex-grow: 1;
        text-align: right;
    }
    
    /* Upper right positioning */
    .upperRight {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px; /* Adjust font size if necessary */
        color: #666; /* Adjust color if necessary */
    }
    
    /* Lower right positioning */
    .lowerRight {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        color: #666;
    }
    
    /* ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    li {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ccc; 
    } */
    
    
    .cameraItem {
        padding: 30px;
        margin-bottom: 10px;
        border: 1px solid transparent; /* Default border color */
        border-radius: 5px;
        cursor: pointer;
        transition: border-color 0.3s ease;
    }
    
    .cameraItem:hover {
        border-color: var(--Primary-Color); /* Change border color on hover */
    }
    
    .selected {
        border-color: var(--Primary-Color); /* Highlight selected item */
        background-color: #ffffff; /* Optional: light background for selected item */
    }
    
    .loadingOverlay {
        width: 100%;
        /* min-height: 141px; */
        height: 100%;
        display: flex;
        padding: 25px 25px;
        padding-top: 10px;
        border-radius: 12px;
        text-decoration: none;
        /* gap: 10px; */
        cursor: default;
        align-items: center;
        justify-content: space-between;
    
    
    
    
        position: absolute;
        top: 0;
        left: 0;
    
        background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    
    
        z-index: 1000; /* Ensure the overlay is above modal content */
    }
    
    .loadingSpinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        border-top: 4px solid #3498db;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    
    .errorMessage {
        color: red;
        background-color: #fdd;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid red;
        border-radius: 5px;
    }
    
}



